<template>
  <div class="download-wrap">
    <div class="download-title">
      <img src="@/assets/image/logo-b.svg" alt="logo" width="30">
      <span class="ml-2">{{$t('fanhanapp.title')}}</span>
    </div>
    <a class="btn btn-primary btn-sm" href="https://sj.qq.com/myapp/detail.htm?apkName=com.fanhantech.fanhandata" role="button">{{$t('fanhanapp.download')}}</a>
  </div>
</template>

<script>
export default {
  name: 'AppDownload',
  components: {
  },
  props: {
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.download-wrap {
  display: none;
  padding: .5rem 1rem;
  justify-content: space-between;
}
.download-title {
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .download-wrap {
    display: flex;
  }
}
</style>
