<template>
  <div id="app">
    <div class="d-none">
      <img src="@/assets/image/url-img.jpg" alt="fanhan">
    </div>
    <AppDownload></AppDownload>
    <Header></Header>
    <IndexHead></IndexHead>
    <IndexScens></IndexScens>
    <IndexSolutions></IndexSolutions>
    <Footer></Footer>
    <BackTop></BackTop>
  </div>
</template>

<script>
import Header from '@/components/header'
import AppDownload from '@/components/AppDownload'
import Footer from '@/components/footer'
import BackTop from '@/components/BackTop'

import IndexHead from './components/IndexHead'
import IndexScens from './components/IndexScens'
import IndexSolutions from './components/IndexSolutions'

export default {
  name: 'App',
  components: {
    Header,
    AppDownload,
    IndexSolutions,
    IndexHead,
    IndexScens,
    Footer,
    BackTop
  }
}
</script>

<style lang="less" scoped>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
