





















import Vue from 'vue'
interface iData{
  num1: string,
  num2: string,
  timer: any,
  timer2: any
}
const IndexHead = Vue.extend({
  name: 'IndexHead',
  data (): iData {
    return {
      num1: '0',
      num2: '0',
      timer: null,
      timer2: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        this.timer = setTimeout(() => { this.timedCount(0, 36, 'num1', 'timer') }, 50)
        this.timer2 = setTimeout(() => { this.timedCount(0, 50, 'num2', 'timer2') }, 50)
      }, 200)
    })
  },
  methods: {
    timedCount (nums = 0, max: number, name: string, timer: string) {
      const number: string = (100 / 10).toString()
      const count: number = parseInt(number)
      nums = nums + count
      this[name] = nums < 10 ? `0${nums}` : nums.toString()
      // console.log(nums, this.num1, 'res')
      // 设置条件使停止计时
      if (nums < max) {
        this[timer] = setTimeout(() => { this.timedCount(nums, max, name, timer) }, 50)
      } else {
        this[name] = max
        clearInterval(this[timer])
      }
    }
  }
})
export default IndexHead
