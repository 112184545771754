


































import Vue from 'vue'
import SectionTitle from '@/components/SectionTitle.vue'
const IndexSolutions = Vue.extend({
  // 类型推断已启用
  components: {
    SectionTitle
  }
})

export default IndexSolutions

