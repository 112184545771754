

















































import SectionTitle from '@/components/SectionTitle.vue'
export default {
  name: 'IndexScense',
  components: {
    SectionTitle
  }
}
